<template>
  <div>
    <b-row>
      <b-col sm="12">
        <div :class="animation">
          <b-card title="Mise a jour des comptes manuels">
            <b-row>
              <b-col sm="6">
                <b-form-group label="Moyen de change">
                  <b-form-select
                    v-model="$v.account.id.$model"
                    type="text"
                    :class="{
                      'is-invalid': $v.account.id.$error,
                    }"
                    :options="tarifsOptions"
                  />
                  <div
                    v-if="$v.account.id.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.account.solde.required">{{
                      $t("errors.required")
                    }}</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Montant">
                  <b-form-input
                    v-model.number="$v.account.solde.$model"
                    :class="{
                      'is-invalid': $v.account.solde.$error,
                    }"
                  />
                  <div
                    v-if="$v.account.solde.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.account.solde.required">{{
                      $t("errors.required")
                    }}</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div
                  v-if="process"
                  class="text-center mt-1 mb-2"
                >
                  <b-spinner variant="primary" />
                </div>
                <b-button
                  variant="secondary"
                  class="float-left"
                  @click="reset"
                >
                  {{ $t("form.refresh") }}
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  @click="tryToSave"
                >
                  Enrégistrer
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      animation: null,
      allCurrencies: [],
      account: {
        id: null,
        solde: null,
      },
    }
  },
  validations: {
    account: {
      id: {
        required,
      },
      solde: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateSoldeSuccess',
      error: 'updateSoldeError',
      process: 'updateSoldeProcess',
    }),
    tarifsOptions() {
      const tarifs = this.allCurrencies
      const data = [
        { value: null, text: 'Chosir', disabled: true },
      ]
      if (tarifs.length > 0) {
        const manuals = tarifs.filter(item => item.is_auto === false)
        manuals.forEach(item => {
          const current = {
            value: item.id,
            text: item.libelle,
          }
          data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllCurrencies()
      },
    },
    success(val) {
      if (val) {
        this.reset()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise a jour de compte',
            text: 'Update successufully',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'admin-settings-allpayment' })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise a jour de compte',
            text: 'Une erreur est survenue lors de la mise a jour.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateSolde']),
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data)
        .catch(() => [])
    },
    formatter(value) {
      return value.toUpperCase()
    },
    reset() {
      this.account = {
        id: null,
        solde: null,
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },

    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.updateSolde({
        id: this.account.id,
        value: this.account.solde,
      })
    },
  },
}
</script>
<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
